@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
/* setting up the background image to cover the whole screen */

$base-color: rgb(145, 13, 13);
$base-color-opacity: rgba(145, 13, 13, 0.8);

* + * {
  font-family: Roboto, Helvetica, sans-serif;
}
* {
  &::selection {
    background-color: $base-color;
    color: white;
  }
}
html {
  background: url("./components/images/new-york.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
}

body {
  margin: 0;
}

.App {
  font-family: sans-serif;
  height: 100vh;
  display: grid;
  grid-template-columns: minmax(auto, 350px) minmax(auto, auto);
  grid-template-areas: "aside main";
}

.hamburger {
  display: none;
}

/* profile picture and links */

.main-links {
  grid-area: aside;
  // max-width: 100%;
  background-color: $base-color-opacity;
  text-align: center;
  height: 100%;
  overflow-y: auto;

  & .profilePicture {
    width: 152px;
    border-radius: 100%;
    margin-top: 50px;
    margin-bottom: 10px;
  }
}
.profile-name {
  text-transform: uppercase;
  font-weight: 500;
  color: rgb(247, 247, 247);
  font-size: 22px;
  margin: 0;
}

.profile-description {
  text-transform: uppercase;
  font-weight: normal;
  color: rgba(255, 241, 241, 0.8);
  font-size: 16px;
  margin: 0;
}
// profile list
.lists {
  margin-top: 4rem;
  font-size: 1.1rem;
  .caret {
    visibility: hidden;
  }

  & ul {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    list-style: none;
    text-align: start;
    margin-top: 4rem;

    & li {
      line-height: 45px;
      &:hover {
        color: white;
      }
    }
    & li:hover .caret {
      visibility: visible;
      margin-left: 5px;
    }
  }
}
// Links
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

// Social links
.social {
  color: rgba(255, 241, 241, 0.6);
  margin-top: 4rem;

  & .socialLinks {
    margin-bottom: 10px;
    padding-top: 15px;
    & a {
      font-size: 30px;
      margin-left: 20px;

      &:hover {
        color: white;
      }
    }
    & .envelope {
      font-size: 33px;
      margin-left: 0;
    }
  }
}
// contacts page
.contact-page {
  grid-area: main;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgba(92, 77, 77, 0.8);
  & .contact-intro {
    & h2 {
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 3rem;
    }
    & p {
      color: black;
      font-size: 1.2rem;
      margin-bottom: 3rem;
    }
  }
}

.contact-social-links {
  color: black;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: repeat(5, 30px);
  grid-template-areas:
    "email emailIcon"
    "linkedIn linkedInIcon"
    "gitHub gitHubIcon"
    "twitter twitterIcon"
    "instagram instagramIcon";
  font-size: 1.2rem;
  align-items: center;
  & a {
    display: grid;
    grid-template-columns: 30px 170px;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    color: $base-color-opacity;

    & :hover {
      text-decoration: underline;
    }
  }
  & p {
    margin: 0;
  }

  & .emailLabel {
    grid-area: email;
  }

  & .envelope {
    grid-area: emailIcon;
  }

  & .linkedInLabel {
    grid-area: linkedIn;
  }

  & .linkedIn {
    grid-area: linkedInIcon;
  }

  & .githubLabel {
    grid-area: gitHub;
  }

  & .gitHub {
    grid-area: gitHubIcon;
  }

  & .twitterLabel {
    grid-area: twitter;
  }

  & .twitter {
    grid-area: twitterIcon;
  }

  & .instagramLabel {
    grid-area: instagram;
  }

  & .instagram {
    grid-area: instagramIcon;
  }
}

// Home page
#home {
  padding-left: 100px;
  height: 100vh;
  display: grid;
  grid-area: main;
  align-items: space-between;

  & h1 {
    font-size: 3rem;
    margin-top: 100px;
    color: $base-color;
    text-shadow: 2px 2px black;
  }

  & p {
    font-size: 2rem;
    color: white;
    text-shadow: 1px 1px black;
  }
  & button {
    padding: 15px 30px;
    font-size: 1.5rem;
    font-weight: 900;
    background: none;
    align-self: center;

    border: 2px solid rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      cursor: pointer;
      color: white;
      border: 2px solid white;
    }
  }
}

// Portfolio

#portfolio {
  overflow-y: auto;
  grid-area: main;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);

  & .portoflio-description {
    padding-left: 50px;
    padding-top: 50px;
    color: rgb(51, 51, 51);
  }
  & h2 {
    margin-top: 0;
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
  }

  & hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  & .projects {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 300px 300px 300px;
    grid-gap: 50px;
  }
}

.container {
  position: relative;
  height: 400px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #f1defa;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  padding: 2px 16px;
  border-radius: 10px;

  &:last-child {
    margin-bottom: 20px;
  }

  & #tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  & .tag {
    width: auto;
    border: 1px solid gray;
    padding: 2px 5px;
    background: rgba(255, 255, 255, 0.87);
    color: black;
    margin-left: 2px;
    margin-bottom: 2px;
  }

  & img {
    display: block;
    width: 300px;
    height: 400px;
    border-radius: 10px;
  }

  & .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(29, 106, 154, 0.72);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;

    & .innerItems {
      color: rgba(255, 255, 227, 0.9);
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      height: 400px;
      align-items: center;
      justify-content: space-around;

      & p {
        font-size: 1.1rem;
      }

      & .project-buttons {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-content: flex-end;

        & button {
          background: none;
          padding: 10px 20px;
          border: 1px solid rgba(230, 227, 227, 0.9);
          cursor: pointer;
          color: rgba(230, 227, 227, 0.9);
          // justify-self: flex-end;
          font-size: 1.1rem;

          &:hover {
            color: white;
            border: 2px solid white;
          }
        }
      }
    }
  }

  &:hover .overlay {
    visibility: visible;
    opacity: 1;
    border-radius: 10px;
  }

  &:hover {
    transform: scale(1.1);
    transition: all 1.5s ease;
    z-index: 9999;
  }
}

// Not found page

.NotFound {
  overflow-y: auto;
  grid-area: main;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & h1 {
    font-size: 10em;
    margin-bottom: 0;
  }

  & h3 {
    font-size: 3rem;
  }
  & p {
    font-size: 1.5rem;
  }
  & p:hover {
    text-decoration: underline;
    color: $base-color-opacity;
  }
}

// blog

#blogWork {
  font-size: 0.5rem;
}

// CV

#cv-container {
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background-color: rgb(193, 193, 193);
  & a {
    color: $base-color;
    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    font-weight: 300;
    font-size: 14px;
  }
  & li {
    font-weight: 300;
    font-size: 14px;
  }
  & #cv {
    background-color: white;
    box-shadow: 2px 2px 1px #606060;
    max-width: 760px;
    margin: 80px auto;
    display: grid;
    padding: 80px;
    grid-template-columns: 100%;
    grid-template-rows:
      minmax(auto, auto) minmax(auto, auto) minmax(auto, auto) minmax(
        auto,
        auto
      )
      minmax(auto, auto)
      minmax(auto, auto)
      minmax(auto, auto);
    grid-template-areas:
      "mainInfo"
      "skills"
      "cv-experience"
      "education";

    & li {
      line-height: 1.5;
    }

    & .timeline {
      width: 100%;
      background-color: #eee;
      color: #777;
      padding: 7px 2px 7px 0;
      margin-right: 7px;
      margin-bottom: 5px;
      border-radius: 2px;
      font-size: 13px;
      text-align: right;
      font-weight: 300;
    }

    & .timeDate,
    .location {
      font-size: 13px;

      text-align: right;
      color: #777;
      margin: 0 0 5px 0;
    }

    & .mainInfo {
      display: grid;
      grid-template-columns: 150px auto minmax(auto, auto);
      grid-template-rows: minmax(auto auto);
      grid-template-areas: "picture mainInfo links";
      grid-gap: 20px;

      & img {
        width: 150px;
        grid-area: picture;
        justify-self: right;
      }

      & .description {
        grid-area: mainInfo;
        align-self: start;

        & h1 {
          margin-top: 0;
          margin-bottom: 5px;
          font-size: 30px;
          color: $base-color;
        }
        & h2 {
          margin-top: 0;
          margin-bottom: 5px;
          color: #777;
          font-size: 24px;

          font-weight: 300;
        }
        & p {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 12px;
          line-height: 1.5;
          &:last-child {
            margin-bottom: 16px;
          }
        }
      }
      & .cv-main-links {
        align-self: start;
        padding-left: 20px;
        grid-area: links;
        border-left: 1px solid #777;
        & .hidden {
          display: none;
        }
        & a {
          display: block;
        }
      }
    }
    & .skills {
      display: grid;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-areas: ". skills";
      grid-gap: 20px;

      & .cv-skills {
        grid-area: skills;
        justify-self: start;

        & h3 {
          color: $base-color;
          font-weight: 300;
          font-size: 24px;
        }
      }
    }

    & .experience {
      display: grid;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-rows:
        50px minmax(auto, auto) minmax(auto, auto) minmax(auto, auto)
        minmax(auto, auto);
      grid-template-areas:
        ". experience"
        "self-taught-info self-taught-info"
        "panevski panevski"
        "daybor daybor"
        "usa usa";
      gap: 20px;

      & h3 {
        color: $base-color;
        grid-area: experience;
        font-weight: 300;
        font-size: 24px;
      }

      & .selfTaught {
        grid-area: self-taught-info;
        display: grid;

        grid-template-columns: 150px minmax(auto, auto);
        grid-template-rows: minmax(auto, auto);
        grid-template-areas: "timeline description";
        grid-gap: 20px;

        & .self-taught-timeline {
          grid-area: timeline;
          & h4 {
            margin-top: 0;
          }
        }
        & .cv-projects-description {
          grid-area: description;

          & p {
            margin-top: 0;
          }
          & h4 {
            font-size: 0.9rem;
            margin-top: 0;
            font-weight: 400;
          }
        }
      }
    }
    & .panevski {
      display: grid;
      grid-area: panevski;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-rows: minmax(auto, auto);
      grid-template-areas: "timeline description";
      grid-gap: 20px;

      & .panevski-timeline {
        grid-area: timeline;
        & h4 {
          margin-top: 0;
        }

        & a {
          font-size: 13px;
          display: block;
          text-align: right;
        }
      }
      & .panevski-description {
        h3 {
          grid-area: description;
          margin-top: 0;
          font-size: 18px;
          font-weight: 400;
          color: black;
          margin-bottom: 0.8rem;
        }

        & h4 {
          font-size: 0.9rem;
          margin-top: 0;
          font-weight: 400;
        }
      }
    }
    & .daybor {
      grid-area: daybor;
      display: grid;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-rows: minmax(auto, auto);
      grid-template-areas: "timeline description";
      grid-gap: 20px;

      & .daybor-timeline {
        grid-area: timeline;
        & h4 {
          margin-top: 0;
        }
      }

      & .daybor-description {
        h3 {
          grid-area: description;
          font-size: 18px;
          font-weight: 400;
          margin-top: 0;
          color: black;
          margin-bottom: 0.8rem;
        }

        & h4 {
          font-size: 0.9rem;
          margin-top: 0;
          font-weight: 400;
        }
      }
    }
    & .usa {
      grid-area: usa;
      display: grid;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-rows: minmax(auto, auto);
      grid-template-areas: "timeline description";
      grid-gap: 20px;

      & .usa-timeline {
        grid-area: timeline;
        & h4 {
          margin-top: 0;
        }
      }

      & .usa-description {
        h3 {
          grid-area: description;
          font-size: 18px;
          font-weight: 400;
          margin-top: 0;
          color: black;
          margin-bottom: 0.8rem;
        }
      }
    }

    & .education {
      display: grid;
      grid-template-columns: 150px minmax(auto, auto);
      grid-template-rows: 50px minmax(auto, auto);
      grid-template-areas:
        ". education"
        "college college";
      gap: 20px;

      & h3 {
        color: $base-color;
        grid-area: education;
        font-weight: 300;
        font-size: 24px;
      }
      & .eccf {
        grid-area: college;
        display: grid;
        grid-template-columns: 150px minmax(auto, auto);
        grid-template-rows: minmax(auto, auto);
        grid-template-areas: "timeline description";
        grid-gap: 20px;

        & .eccf-timeline {
          grid-area: timeline;
          & h4 {
            margin-top: 0;
          }
          & a {
            font-size: 13px;
            display: block;
            text-align: right;
          }
        }

        & .eccf-description {
          h3 {
            grid-area: description;
            font-size: 18px;
            font-weight: 400;
            margin-top: 0;
            color: black;
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1380px) {
  #portfolio {
    & .projects {
      grid-template-columns: 300px 300px;

      grid-gap: 50px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  #portfolio {
    & .projects {
      grid-template-columns: 300px 300px;

      grid-gap: 50px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  //CV
  #cv-container {
    background-color: white;
    display: flex;
    justify-content: center;

    & #cv {
      box-shadow: none;
      padding: 20px;
      margin: 0;
      grid-template-columns: 100%;

      grid-template-areas:
        "mainInfo"
        "skills"
        "cv-experience"
        "education";

      & .mainInfo {
        grid-template-columns: 100%;
        grid-template-rows: repeat(3, minmax(auto, auto));
        grid-template-areas:
          "picture"
          "description"
          "main-links";
        grid-gap: 0;
        justify-items: center;
        align-items: center;
        text-align: center;

        & img {
          grid-area: picture;
          margin: 0 auto;
          border-radius: 5px;
          margin-bottom: 15px;
        }
        & .description {
          grid-area: description;
          margin-top: 0;

          & p {
            font-size: 14px;
            width: 70%;

            margin: -5px auto 0 auto;

            &:last-child {
              margin: 0 auto;
            }
          }
        }
        & .cv-main-links {
          grid-area: main-links;
          border: none;

          & .hidden {
            display: inline;
          }

          & a {
            margin-top: 10px;
            font-size: 14px;
            display: inline-block;
          }
        }
      }
    }
  }

  // Menu

  .App {
    grid-template-columns: minmax(auto, auto);
    grid-template-rows: 300px minmax(auto, auto);
    grid-template-areas:
      "menu"
      "content";
    grid-gap: 40px;
  }

  // /* profile picture and links */

  .main-links {
    grid-area: menu;
    padding-top: 40px;
    overflow-y: hidden;

    & #menu {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 230px 50px;
      grid-template-areas:
        "picture picture"
        "social menu";
      grid-gap: 20px 0;
      border-bottom: 1px solid #eee;

      & .profile {
        grid-area: picture;
      }

      & .lists {
        grid-area: menu;
        margin-top: 0;
        justify-self: end;
        display: grid;
        width: 50vw;
        border-top: 1px solid #eee;
        & .caret {
          visibility: hidden;
        }

        & ul {
          margin-top: 30px;
          text-align: right;
          visibility: hidden;
          font-size: 18px;
        }

        & .hamburger {
          color: rgba(255, 241, 241, 0.6);
          text-align: center;
          justify-self: end;
          padding-top: 5px;
          padding-right: 20px;

          cursor: pointer;
          display: block;

          & div {
            width: 35px;
            height: 4px;
            background-color: rgb(189, 179, 179);
            margin: 6px 0;
          }
        }
      }

      & .social {
        width: 50vw;
        grid-area: social;
        margin-top: 0;
        justify-self: start;
        border-top: 1px solid #eee;
        text-align: start;

        & a {
          font-size: 20px;
          margin-left: 10px;
        }

        & h4 {
          display: none;
        }
      }
    }

    & .profilePicture {
      margin-top: 0;
    }
  }

  #home,
  .contact-page,
  #portfolio {
    height: auto;
    grid-area: content;
  }
  #home {
    & h1 {
      margin-top: 0;
      padding: 40px 40px 40px 0;
      color: white;
    }
  }
  // 404
  .NotFound {
    font-size: 0.5rem;
  }

  // CV
  #cv-container {
    height: 100%;
    width: auto;
    margin-top: -350px;
    padding: auto 0;
    overflow-x: hidden;

    & #cv {
      overflow-x: hidden;
    }
  }
  .contact-page {
    height: 100vh;
    justify-content: flex-start;
  }

  .contact-social-links {
    grid-template-columns: 100px auto;

    & a {
      display: flex;
      justify-content: flex-start;
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
  }

  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 5px);
    transform: rotate(-45deg) translate(-9px, 5px);
  }

  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }

  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-7px, -6px);
    transform: rotate(45deg) translate(-7px, -6px);
  }
}

@media only screen and(max-width: 770px) {
  // .main-links {
  //   & #menu {
  //     & .lists {
  //       width: auto;
  //     }
  //   }
  // }
  #cv-container {
    background-color: white;
    display: flex;
    justify-content: center;

    & #cv {
      box-shadow: none;
      padding: auto 0;
      margin: auto 0;
      grid-template-columns: 100%;

      & .mainInfo {
        grid-template-columns: 100%;
        grid-template-rows: repeat(3, minmax(auto, auto));
        grid-template-areas:
          "picture"
          "description"
          "main-links";
        grid-gap: 0;
        justify-items: center;
        align-items: center;
        text-align: center;

        & img {
          grid-area: picture;
          margin: 0 auto;
          border-radius: 5px;
          margin-bottom: 15px;
        }
        & .description {
          grid-area: description;
          margin-top: 0;

          & p {
            font-size: 14px;
            width: 70%;

            margin: -5px auto 0 auto;

            &:last-child {
              margin: 0 auto;
            }
          }
        }
        & .cv-main-links {
          grid-area: main-links;
          border: none;

          & .hidden {
            display: inline;
          }

          & a {
            margin-top: 10px;
            font-size: 14px;
            display: inline-block;
          }
        }
      }
      & .timeline,
      .timeDate,
      .location {
        background: none;
        text-align: left;
        margin: 0;
        padding: 0;
      }

      & .skills {
        grid-template-columns: 100%;
        grid-template-areas: "skills";

        & .cv-skills {
          grid-area: skills;
        }
      }

      & .experience {
        grid-gap: 0;
        & .selfTaught {
          grid-template-columns: 100%;
          grid-template-areas:
            "timeline"
            "projects";

          & .self-taught-timeline {
            grid-area: timeline;
          }

          & .cv-projects-description {
            grid-area: projects;
          }
        }

        & .panevski,
        .daybor,
        .usa {
          grid-template-columns: 100%;
          grid-template-areas:
            "timeline"
            "description";
          border-top: 1px solid #eee;

          & .panevski-timeline,
          .daybor-timeline,
          .usa-timeline {
            margin-top: 15px;
            grid-area: timeline;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;

            & .location {
              justify-self: end;
              text-align: end;
            }

            & .timeDate {
              text-align: center;
            }
            & a {
              display: none;
            }
          }
        }
      }

      & .education {
        grid-template-columns: 100%;
        grid-template-areas:
          "education"
          "college";

        & h3 {
          grid-area: education;
        }

        & .eccf {
          grid-area: college;
          grid-template-columns: 100%;
          grid-template-areas:
            "timeline"
            "description";
          margin-bottom: 10px;

          & .eccf-timeline {
            margin-top: 15px;
            grid-area: timeline;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: end;

            & .location {
              justify-self: end;
            }
            & a {
              display: none;
            }
          }
          & .eccf-description {
            grid-area: description;
          }
        }
      }
    }
  }
  #portfolio {
    & .projects {
      grid-template-columns: 300px;
    }
    & .portoflio-description {
      padding-left: 10px;
      padding-top: 10px;
      color: rgb(51, 51, 51);

      & p {
        margin-bottom: 0;
      }
    }

    & .projects {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 0;
    }
  }
  .container {
    height: 333px;
    width: 250px;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;

    & img {
      width: 250px;
      height: 333px;
    }

    & .overlay {
      & .innerItems {
        height: 333px;

        & p {
          font-size: 0.9rem;
        }
      }
    }
  }

  #home {
    padding-left: 10px;

    & h1 {
      padding: 0;
    }
  }
}

@media only screen and(max-width: 600px) {
  .main-links {
    & #menu {
      & .lists {
        width: auto;

        & li {
          white-space: pre;
        }
      }
    }
  }
}
